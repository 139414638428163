import React from 'react';
import { Form } from 'react-bootstrap';

const CurrencySelector = ({ currencies, selectedCurrency, onCurrencyChange }) => {
  return (
    <Form.Group controlId="currencySelect">
      <Form.Label>Select Currency</Form.Label>
      <Form.Control
        as="select"
        value={selectedCurrency}
        onChange={onCurrencyChange}
        className="bg-dark text-light border-secondary"
      >
        {currencies.map((currency, index) => (
          <option key={index} value={currency}>
            {currency}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};

export default CurrencySelector;
