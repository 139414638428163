import React, { useEffect } from 'react';

const ExchangeRatesTable = ({ rates, selectedBank, onSelectBank, onSort, sortConfig }) => {
  useEffect(()=>{
    if(rates.length>0){
      onSelectBank(rates[0])
    }
  },[rates])
  return (
    <table className="table table-dark table-striped table-bordered">
      <thead>
        <tr>
          <th onClick={() => onSort('bankName')} className="sortable">
            Bank {sortConfig.key === 'bankName' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
          </th>
          <th onClick={() => onSort('buying')} className="sortable">
            Buying Rate {sortConfig.key === 'buying' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
          </th>
          <th onClick={() => onSort('selling')} className="sortable">
            Selling Rate {sortConfig.key === 'selling' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
          </th>
          <th>Select</th>
        </tr>
      </thead>
      <tbody>
        {rates.map((rate, index) => (
          <tr>
            <td>{rate.bankName}</td>
            <td>{rate.buying}</td>
            <td>{rate.selling}</td>
            <td>
              <input
                type="radio"
                name="bankSelect"
                value={rate.bankName}
                checked={selectedBank === rate.bankName}
                onChange={() => onSelectBank(rate)}

              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ExchangeRatesTable;
