import React, { useState, useEffect } from 'react';
import CurrencySelector from './components/CurrencySelector';
import ExchangeRatesTable from './components/ExchangeRatesTable';
import CurrencyCalculator from './components/CurrencyCalculator';
import './App.css'; // Custom CSS file for table styles

const App = () => {
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [availableCurrencies, setAvailableCurrencies] = useState([]);
  const [filteredRates, setFilteredRates] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [selectedBankObj, setSelectedBankObj] = useState({});
  const [exchangeRatesData, setExchangeRatesData] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'bankName', direction: 'asc' });



  useEffect(() => {
    // Fetch the exchange rates from the public folder
    fetch('/exchangeRates.json')
      .then(response => response.json())
      .then(data => setExchangeRatesData(data))
      .catch(error => console.error('Error fetching exchange rates:', error));
  }, []);

  useEffect(() => {
    if (!exchangeRatesData) return;

    const currencies = new Set();
    for (const bank in exchangeRatesData) {
      exchangeRatesData[bank].forEach(rate => {
        // Ensure rate and Currency exist before accessing or modifying them
        if (rate && rate.Currency) {
          currencies.add(rate.Currency.replace(/[^a-zA-Z]/g, ''));
        }
      });
    }
    setAvailableCurrencies([...currencies]);
  }, [exchangeRatesData]);

  useEffect(() => {
    if (!exchangeRatesData) return;

    const rates = [];
    for (const bank in exchangeRatesData) {
      const bankRates = exchangeRatesData[bank].find(
        rate => rate.Currency && rate.Currency.includes(selectedCurrency)
      );
      if (bankRates) {
        rates.push({
          bankName: bank,
          buying: bankRates.Buying,
          selling: bankRates.Selling,
        });
      }
    }

    setFilteredRates(rates);
    setSelectedBank(rates[0] ? rates[0].bankName : null);  // Set first bank as default if no selection
  }, [selectedCurrency, exchangeRatesData]);

  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e.target.value);
  };

  const handleBankSelect = (bank) => {
    // console.log(bank, "bank")// rate.bankName
    setSelectedBank(bank.bankName);
    setSelectedBankObj(bank)
  };

  // Sorting function
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sortedRates = [...filteredRates].sort((a, b) => {
      if (a[key] < b[key]) return sortConfig.direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });

    setFilteredRates(sortedRates);
  };


  return (
    <div className="container mt-4">
      <h1 className="mb-4" align="center">Exchange Rates from Birr to {selectedCurrency}</h1>
      <CurrencySelector
        currencies={availableCurrencies}
        selectedCurrency={selectedCurrency}
        onCurrencyChange={handleCurrencyChange}
      />
      <ExchangeRatesTable
        rates={filteredRates}
        selectedBank={selectedBank}
        onSelectBank={handleBankSelect}
        onSort={handleSort}
        sortConfig={sortConfig}
      />
      
      {selectedBank && (
        <CurrencyCalculator
          selectedBank={selectedBankObj}
          selectedCurrency={selectedCurrency}

        />
      )}
    </div>
  );
};

export default App;
