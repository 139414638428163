import React, { useState, useEffect } from 'react';

const CurrencyCalculator = ({ selectedBank, selectedCurrency }) => {
  const [amount, setAmount] = useState(0); // Input amount
  const [conversionType, setConversionType] = useState('buying'); // 'buying' or 'selling'
  const [convertedAmount, setConvertedAmount] = useState(null); // Converted amount

  // Function to calculate conversion
  const calculateConversion = (type, amount) => {
    if (!selectedBank || !amount || amount <= 0) return; // Validate bank and amount

    const rate = type === 'buying' ? selectedBank.buying : selectedBank.selling;
    if (rate) {
      const result = type === 'buying' ? amount * rate : amount / rate; // Multiplying for buying
      setConvertedAmount(result.toFixed(2)); // Format result to 2 decimal places
    } else {
      setConvertedAmount(null); // Reset if invalid
    }
  };

  // Trigger calculation when amount, conversion type, or selected bank changes
  useEffect(() => {
    if (selectedBank && amount > 0) {
      calculateConversion(conversionType, amount);
    }
  }, [amount, conversionType, selectedBank]);

  const handleAmountChange = (e) => {
    const newAmount = parseFloat(e.target.value);
    setAmount(newAmount);
  };

  const handleConversionTypeChange = (e) => {
    setConversionType(e.target.value);
  };

  // Determine the result text based on conversion type
  const resultText = () => {
    if (convertedAmount === null) return "Enter a valid amount to calculate.";
    return conversionType === 'buying'
      ? `Converted Amount: ${convertedAmount} Birr using ${selectedBank?.bankName} ${conversionType} rate`
      : `Converted Amount: ${convertedAmount} ${selectedCurrency} using ${selectedBank?.bankName} ${conversionType} rate`;
  };

  return (
    <div className="calculator mt-4">
      <h4>Currency Calculator</h4>
      {selectedBank && <p>Selected Bank: {selectedBank.bankName}</p>}
      <input
        type="number"
        value={amount}
        onChange={handleAmountChange}
        placeholder="Enter amount"
        className="form-control mb-2"
      />
      <div className="mb-3">
        <label className="me-3">
          <input
            type="radio"
            value="buying"
            checked={conversionType === 'buying'}
            onChange={handleConversionTypeChange}
          />{' '}
          From {selectedCurrency} to Birr
        </label>
        <label>
          <input
            type="radio"
            value="selling"
            checked={conversionType === 'selling'}
            onChange={handleConversionTypeChange}
          />{' '}
          From Birr to {selectedCurrency}
        </label>
      </div>
      <p>{resultText()}</p>
    </div>
  );
};

export default CurrencyCalculator;
